<template>
  <div>
    <div
      class="modal fade"
      id="createpin2"
      tabindex="-1"
      aria-labelledby="createpin"
      aria-hidden="true"
    >
      <div
        class="modal-dialog modal-dialog-centered"
        style="margin-bottom: 50px;"
      >
        <div class="modal-content">
          <div class="modal-header" style="border-bottom: none;">
            <h5 class="modal-title title-modal" id="fundwallet">
              Create Transaction Pin
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <hr />
          <div class="modal-body payment-approval-body">
            <div class="transaction-pin">
              <input
                @keyup.prevent="moveToNextField('secondInput')"
                ref="firstInput"
                type="number"
                class="form-control input-pin"
                maxlength="1"
                v-model="firstpin"
                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
              />
              <!-- onfocus="(this.type='number')" -->
              <input
                @keyup.prevent="moveToNextField('thirdInput')"
                ref="secondInput"
                type="number"
                class="form-control input-pin"
                maxlength="1"
                v-model="secondpin"
                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
              />
              <input
                @keyup.prevent="moveToNextField('fouthInput')"
                ref="thirdInput"
                type="number"
                class="form-control input-pin"
                maxlength="1"
                v-model="thirdpin"
                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
              />
              <input
                @keyup.prevent="moveToNextField('fouthInput')"
                ref="fouthInput"
                type="number"
                class="form-control input-pin"
                maxlength="1"
                v-model="fourthpin"
                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
              />
            </div>
          </div>
          <div class="modal-footer" style="border: none; flex-wrap: nowrap;">
            <button
              @click="createTransactionPin()"
              type="button"
              class="btn continue-btn small-btn"
              :disabled="
                pinloader ||
                  firstpin == '' ||
                  secondpin == '' ||
                  thirdpin == '' ||
                  fourthpin == ''
              "
            >
              SET PIN
              <i
                class="fa fa-spinner fa-spin ml-3 f-12 pinloader"
                v-if="pinloader"
              ></i>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade"
      id="createpin"
      tabindex="-1"
      aria-labelledby="createpin"
      aria-hidden="true"
    >
      <div
        class="modal-dialog modal-dialog-centered"
        style="margin-bottom: 50px;"
      >
        <div class="modal-content">
          <div class="modal-header" style="border-bottom: none;">
            <h5 class="modal-title title-modal" id="fundwallet">
              Create Transaction Pin
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <hr />
          <div class="modal-body payment-approval-body">
            <div>
              <label class="d-flex mt-1 label-tags">Transaction pin (4)</label>
              <div class="input-group">
                <input
                  type="password"
                  class="form-control input-field"
                  maxlength="4"
                  v-model="pin"
                  placeholder="Enter 4 digit transaction pin"
                  oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                />
                <!-- onfocus="(this.type='number')" -->
              </div>
            </div>
            <div>
              <label class="d-flex mt-4 label-tags"
                >Confirm transaction pin</label
              >
              <div class="input-group">
                <input
                  type="password"
                  class="form-control input-field"
                  maxlength="4"
                  v-model="confirmpin"
                  placeholder="Confirm transaction pin"
                  oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                />
                <!-- onfocus="(this.type='number')" -->
              </div>
            </div>
          </div>
          <div class="modal-footer" style="border: none; flex-wrap: nowrap;">
            <button
              @click="createTransactionPin()"
              type="button"
              class="btn continue-btn small-btn"
              :disabled="
                pinloader ||
                  pin == '' ||
                  pin.length != 4 ||
                  confirmpin == '' ||
                  confirmpin.length != 4
              "
            >
              SET PIN
              <i
                class="fa fa-spinner fa-spin ml-3 f-12 pinloader"
                v-if="pinloader"
              ></i>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade"
      id="changepin"
      tabindex="-1"
      aria-labelledby="changepin"
      aria-hidden="true"
    >
      <div
        class="modal-dialog modal-dialog-centered"
        style="margin-bottom: 50px;"
      >
        <div class="modal-content">
          <div class="modal-header" style="border-bottom: none;">
            <h5 class="modal-title title-modal" id="fundwallet">
              Change Pin
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <hr />
          <div class="modal-body payment-approval-body">
            <div>
              <label class="d-flex mt-1 label-tags">Old pin</label>
              <div class="input-group">
                <input
                  type="password"
                  class="form-control input-field"
                  v-model="oldpin"
                  placeholder="Enter old pin"
                />
                <!-- onfocus="(this.type='number')" -->
              </div>
            </div>
            <div>
              <label class="d-flex mt-4 label-tags">New pin</label>
              <div class="input-group">
                <input
                  type="password"
                  class="form-control input-field"
                  maxlength="4"
                  v-model="newpin"
                  placeholder="Enter new pin"
                  oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                />
                <!-- onfocus="(this.type='number')" -->
              </div>
            </div>
            <div>
              <label class="d-flex mt-4 label-tags">Confirm new pin</label>
              <div class="input-group">
                <input
                  type="password"
                  class="form-control input-field"
                  maxlength="4"
                  v-model="confirmnewpin"
                  placeholder="Confirm new pin"
                  oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                />
                <!-- onfocus="(this.type='number')" -->
              </div>
            </div>
          </div>
          <div class="modal-footer" style="border: none; flex-wrap: nowrap;">
            <button
              @click="changePin()"
              type="button"
              class="btn continue-btn small-btn"
              :disabled="
                pinloader2 ||
                  oldpin == '' ||
                  newpin == '' ||
                  newpin.length != 4 ||
                  confirmnewpin == '' ||
                  confirmnewpin.length != 4
              "
            >
              Update Pin
              <i
                class="fa fa-spinner fa-spin ml-3 f-12 pinloader"
                v-if="pinloader2"
              ></i>
            </button>
          </div>
          <div class="reset-forgot-text">
            <p @click="triggerResetPin()">Reset/forget pin</p>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade"
      id="resetpin"
      tabindex="-1"
      aria-labelledby="resetpin"
      aria-hidden="true"
    >
      <div
        class="modal-dialog modal-dialog-centered"
        style="margin-bottom: 50px;"
      >
        <div class="modal-content">
          <div class="modal-header" style="border-bottom: none;">
            <h5 class="modal-title title-modal" id="fundwallet">
              Reset Pin
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <hr />
          <div class="modal-body payment-approval-body">
            <div>
              <label class="d-flex mt-1 label-tags">OTP</label>
              <div class="input-group">
                <input
                  type="password"
                  class="form-control input-field"
                  v-model="otp"
                  placeholder="Enter otp"
                />
                <!-- onfocus="(this.type='number')" -->
              </div>
            </div>
            <div>
              <label class="d-flex mt-4 label-tags">New pin</label>
              <div class="input-group">
                <input
                  type="password"
                  class="form-control input-field"
                  maxlength="4"
                  v-model="newpin2"
                  placeholder="Enter new pin"
                  oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                />
                <!-- onfocus="(this.type='number')" -->
              </div>
            </div>
            <div>
              <label class="d-flex mt-4 label-tags">Confirm new pin</label>
              <div class="input-group">
                <input
                  type="password"
                  class="form-control input-field"
                  maxlength="4"
                  v-model="confirmnewpin2"
                  placeholder="Confirm new pin"
                  oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                />
                <!-- onfocus="(this.type='number')" -->
              </div>
            </div>
          </div>
          <div class="modal-footer" style="border: none; flex-wrap: nowrap;">
            <button
              @click="resetPin()"
              type="button"
              class="btn continue-btn small-btn"
              :disabled="
                pinloader3 ||
                  otp == '' ||
                  newpin2 == '' ||
                  newpin2.length != 4 ||
                  confirmnewpin2 == '' ||
                  confirmnewpin2.length != 4
              "
            >
              Reset Pin
              <i
                class="fa fa-spinner fa-spin ml-3 f-12 pinloader"
                v-if="pinloader3"
              ></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Service } from "../../store/service";
import moment from "moment";
import $ from "jquery";
const Api = new Service();

export default {
  name: "transactionPinModal",
  components: {},
  data() {
    return {
      loader: false,
      pageLoad: false,
      upgradeCardloader: false,
      carddetails: "",
      pinloader: false,
      pinloader2: false,
      pinloader3: false,
      pindetailsshow: false,
      activateBtn: false,
      cardpin: "",
      carduserid: "",
      ovadraftPin: "",
      firstpin: "",
      secondpin: "",
      thirdpin: "",
      fourthpin: "",
      pin: "",
      confirmpin: "",
      newpin: "",
      oldpin: "",
      confirmnewpin: "",
      newpin2: "",
      confirmnewpin2: "",
      otp: ""
    };
  },
  watch: {
    cardpin: function(newValue, oldValue) {
      if (newValue.length === 12) {
        this.getDetails();
      }
    }
  },
  created() {
    this.$root.$refs.createpinmodal = this;
    this.$root.$refs.changePinModal = this;
    // this.$root.$refs.X = this;
  },
  mounted() {
    this.user = JSON.parse(localStorage.getItem("shelta-user"));
    // this.getDetails();
  },
  beforeDestroy() {
    this.loadingState = ""; // save props data to itself's data and deal with it
  },
  methods: {
    // openCardUpgradeModal(cardDetail) {
    //   this.$root.$refs.cardmodal.opencardupgrade(cardDetail);
    // },
    triggerChangePin() {
      $("#changepin")
        .addClass("fade")
        .modal("show");
    },
    triggerResetPin() {
      $("#changepin")
        .removeClass("fade")
        .modal("hide");
      $("#resetpin")
        .addClass("fade")
        .modal("show");
      Api.postRequest(`emailotpforresetingoverdraftransactiontpin`, {})
        .then(res => {
          if (res.data.success) {
            return this.$store.commit("setApiSuccess", "OTP sent to email");
          }
          if (res.data.error) {
            $("#resetepin")
              .removeClass("fade")
              .modal("hide");
            return this.$store.commit("setApiFailed", res.data.error);
          }
        })
        .catch(err => {
          $("#resetepin")
            .removeClass("fade")
            .modal("hide");
          console.log(err);
        });
    },
    openTransactionPin(pindetails) {
      $("#createpin")
        .addClass("fade")
        .modal("show");
    },
    moveToNextField(nextField) {
      this.$refs[nextField].focus();
    },
    async createTransactionPin() {
      this.pinloader = true;
      const numericPattern = /^\d+$/;
      if (
        numericPattern.test(this.pin) === false ||
        numericPattern.test(this.confirmpin) === false
      ) {
        this.pinloader = false;
        return this.$store.commit("setApiFailed", "Pin must be numbers");
      }
      if (this.confirmpin != this.pin) {
        this.pinloader = false;
        return this.$store.commit(
          "setApiFailed",
          "confirm pin must be the same with pin"
        );
      }
      // const pin =
      //   this.firstpin + this.secondpin + this.thirdpin + this.fourthpin;
      try {
        await Api.postRequest(`setoverdraftransactiontpin`, {
          overdraftpin: Number(this.pin)
        })
          .then(res => {
            this.pinloader = false;
            if (res.data.success) {
              $("#createpin")
                .removeClass("fade")
                .modal("hide");
              if (this.$route.name === "JoinOvadraft") {
                this.$router
                  .push({
                    path: `/dashboard/ovadraft`
                  })
                  .catch(() => {});
                // this.$router.go(this.$router.currentRoute);
                this.$router.go();
              }
              return this.$store.commit("setApiSuccess", res.data.success);
            }
            if (res.data.error) {
              $("#createpin")
                .removeClass("fade")
                .modal("hide");
              return this.$store.commit("setApiFailed", res.data.error);
            }
          })
          .catch(err => {
            this.pinloader = false;
            $("#createpin")
              .removeClass("fade")
              .modal("hide");
            console.log(err);
          });
      } finally {
        // console.log("close");
      }
    },
    async changePin() {
      this.pinloader2 = true;
      const numericPattern = /^\d+$/;
      if (
        numericPattern.test(this.newpin) === false ||
        numericPattern.test(this.confirmnewpin) === false
      ) {
        this.pinloader2 = false;
        return this.$store.commit("setApiFailed", "Pin must be numbers");
      }
      if (this.confirmnewpin != this.newpin) {
        this.pinloader2 = false;
        return this.$store.commit(
          "setApiFailed",
          "confirm new pin does not match with new pin"
        );
      }
      if (this.newpin == this.oldpin) {
        this.pinloader2 = false;
        return this.$store.commit(
          "setApiFailed",
          "New pin should not be the same with old pin"
        );
      }
      // const pin =
      //   this.firstpin + this.secondpin + this.thirdpin + this.fourthpin;
      try {
        await Api.postRequest(`updateoverdraftransactiontpinindashboard`, {
          old_pin: Number(this.oldpin),
          new_pin: Number(this.newpin)
        })
          .then(res => {
            this.pinloader2 = false;
            if (res.data.success) {
              $("#changepin")
                .removeClass("fade")
                .modal("hide");
              return this.$store.commit("setApiSuccess", res.data.success);
            }
            if (res.data.error) {
              $("#changepin")
                .removeClass("fade")
                .modal("hide");
              this.oldpin = "";
              this.newpin = "";
              this.confirmnewpin = "";
              return this.$store.commit("setApiFailed", res.data.error);
            }
          })
          .catch(err => {
            this.pinloader2 = false;
            $("#changepin")
              .removeClass("fade")
              .modal("hide");
            console.log(err);
          });
      } finally {
        // console.log("close");
      }
    },
    async resetPin() {
      this.pinloader3 = true;
      const numericPattern = /^\d+$/;
      if (
        numericPattern.test(this.newpin2) === false ||
        numericPattern.test(this.confirmnewpin2) === false
      ) {
        this.pinloader3 = false;
        return this.$store.commit("setApiFailed", "Pin must be numbers");
      }
      if (this.confirmnewpin2 != this.newpin2) {
        this.pinloader3 = false;
        return this.$store.commit(
          "setApiFailed",
          "confirm new pin does not match with pin"
        );
      }
      // const pin =
      //   this.firstpin + this.secondpin + this.thirdpin + this.fourthpin;
      try {
        await Api.postRequest(`resetoverdraftransactiontpinwithotp`, {
          otp: Number(this.otp),
          new_pin: Number(this.newpin2)
        })
          .then(res => {
            this.pinloader3 = false;
            if (res.data.success) {
              $("#resetpin")
                .removeClass("fade")
                .modal("hide");
              return this.$store.commit("setApiSuccess", res.data.success);
            }
            if (res.data.error) {
              this.oldpin = "";
              this.newpin = "";
              this.confirmnewpin = "";
              return this.$store.commit("setApiFailed", res.data.error);
            }
          })
          .catch(err => {
            this.pinloader3 = false;
            $("#resetpin")
              .removeClass("fade")
              .modal("hide");
            console.log(err);
          });
      } finally {
        // console.log("close");
      }
    }
  },
  filters: {
    moment: function(date) {
      return moment(date).format("MMMM Do YYYY, h:mm:ss a");
    },
    moment2: function(date) {
      return moment(date).format("MMMM Do YYYY");
    }
  }
};
</script>

<style lang="scss" scoped>
@font-face {
  font-family: "Lato";
  src: local("Lato-Regular"),
    url("../../assets/fonts/Lato/Lato-Regular.ttf") format("truetype");
}

$primary: #0033ea;
$secondary: #ffffff;
$yellow: #ffb100;
$tatiary: #a2b0e3;
$black: #000000;
$sheltaBlack: #262626;
$offBlack: #262626;
$limeGreen: #3ec250;
$sheltaGrey: #555c74;

.transaction-pin {
  display: flex;
  justify-content: center;
}
.input-pin {
  margin: 8px;
  width: 80px;
  color: $primary;
  text-align: center;
  font-family: Lato;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 60px;
  letter-spacing: -0.64px;
  border-radius: 8px;
  border: 2px solid $primary;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.input-field,
.select-option {
  height: 48px;
  border-radius: 6px;
  border: 1px solid #e7e7e7;
  color: #667085;
  outline: 0;
  box-shadow: none;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  // line-height: 150%;
}
.label-tags {
  color: $offBlack;
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%;
  margin-bottom: 8px;
}

.continue-btn {
  border-radius: 6px;
  background: $primary;
  color: $secondary;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 125%;
  text-transform: capitalize;
  height: 48px;
  outline: 0;
  box-shadow: none;
  width: 100%;
}

.reset-forgot-text {
  p {
    color: $primary;
    font-family: Gotham;
    font-size: 14px;
    cursor: pointer;
    text-align: left;
    margin-left: 15px;
  }
}

@media only screen and (max-width: 599px) {
  .input-pin {
    width: 50px;
    font-size: 26px;
  }
  .small-btn {
    height: 40px;
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
</style>
